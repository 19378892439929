import $ from 'jquery';
import 'slick-carousel';
import 'jscrollpane/script/jquery.jscrollpane.min';
import 'jscrollpane/script/jquery.mousewheel';

window.$ = $;
window.jQuery = $;


$( document ).ready(function() {


    let $instruments = $('.instruments');
    let api;
    let windowWidth = window.innerWidth;
    instrumentsTriggers();

    if (windowWidth < 768) {
        let linksWrap = document.createElement('div');

        $(linksWrap).addClass('navigation__links');
        $(linksWrap).prependTo(".header__nav");
        $('.sign-in').appendTo($(linksWrap));
        $('.sign-out').appendTo($(linksWrap));

        //initHelpsSlider();

    } else {
        //initHelpsTabs();
    }
    $('.tabs__contents').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.tabs__links'
    });
    $('.tabs__links').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.tabs__contents',
        dots: false,
        arrows: false,
        focusOnSelect: true
    });

    function instrumentsTriggers() {
        if (windowWidth < 1001) {
            if (api) {
                api.destroy();
            }
            $instruments.not('.slick-initialized').slick({
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            })
        } else {
            let element = $instruments.jScrollPane({
                autoReinitialise: true
            });
            api = element.data('jsp');
            if ($instruments.hasClass('slick-initialized')) {
                $instruments.slick('unslick');
            }
        }
    }

    let introTimeLine = new TimelineLite();
    let introContainer = document.querySelector('.intro-laptop');
    if (introContainer) {
        introTimeLine
            .to(".intro-laptop__block", {y: 0, opacity: 1, duration: 0.7, ease: 'power1.inOut'})
            .set(".intro-laptop__border", {opacity: 1})
            .to(".intro-laptop__border", {rotate: "22deg", duration: 0.2, ease: 'power1.inOut', onComplete: function () {
                    $(".intro-laptop").addClass("hover");
                }})
            .to(".intro-wrap__line", {y: 0, duration: 0.3, ease: 'power1.inOut'})
            .to(".intro-wrap__komondor", {opacity: 1, duration: 0.3, ease: "power1.inOut"});
    }



    langSelect();
    if ($('select[name=language]')[0]) {
        selectChange();
        $('select[name=language]').change(selectChange);
    }

    mobileMenu();


    //Mobile Menu
    function mobileMenu() {
        let $hamburger = $('.hamburger');
        let $overlay = $('.header__overlay');
        let $menu = $('.header__nav');
        let $menuClose = $('.header-nav-close');
        $hamburger.click(open);
        $overlay.click(close);
        $menuClose.click(close);

        function open() {
            $overlay.addClass('open');
            $menu.addClass('open');
            $('body').addClass('open-mobile-menu');
        }
        function close() {
            $overlay.removeClass('open');
            $menu.removeClass('open');
            $('body').removeClass('open-mobile-menu');
        }
    }



    let TriggerAnimationJoin = false;
    let TriggerAnimationJoinElem = $("#TriggerAnimationJoin");

    let TriggerAnimationAccess = false;
    let TriggerAnimationAccessElem = $("#TriggerAnimationAccess");

    let TriggerAnimationPartners = false;
    let TriggerAnimationPartnersElem = $("#TriggerAnimationPartners");

    let TriggerInstrumentsIntro = false;
    let instrumentsIntro = $("#instrumentsIntro");

    let instrumentsContentImages = $(".content-instruments-image");


    $(window).scroll(function() {

        //Header Scroll
        if ($(window).scrollTop() > 0) {
            $(".header").addClass("scroll");
        } else {
            $(".header").removeClass("scroll");
        }

        //Triggers
        if (TriggerAnimationJoinElem[0]) {
            if(!TriggerAnimationJoin && $(window).scrollTop() + $(window).height() >= TriggerAnimationJoinElem.offset().top + TriggerAnimationJoinElem.height()/2) {
                TriggerAnimationJoin = true;
                TweenLite.to('#TriggerAnimationJoin .form-theme__block', {y:0, opacity: 1, duration: 0.7, ease: 'power1.inOut'});
            }
        }

        if (TriggerAnimationAccessElem[0]) {
            if(!TriggerAnimationAccess && $(window).scrollTop() + $(window).height() >= TriggerAnimationAccessElem.offset().top + TriggerAnimationAccessElem.height()/2) {
                TriggerAnimationAccess = true;
                TweenLite.to('#TriggerAnimationAccess .form-theme__block', {y:0, opacity: 1, duration: 0.7, ease: 'power1.inOut'});
            }
        }

        if (TriggerAnimationPartnersElem[0]) {
            if(!TriggerAnimationPartners && $(window).scrollTop() + $(window).height() >= TriggerAnimationPartnersElem.offset().top + TriggerAnimationPartnersElem.height()) {
                TriggerAnimationPartners = true;
                let partnersTimeline = new TimelineLite();

                partnersTimeline
                    .to('.partners-wrap__line', {height:'100%', duration: 0.7, ease: 'power1.inOut'})
                    .to('.partners-wrap__border-right', {height:'50%', duration: 0.7, ease: 'power1.inOut'})
                    .to('.partners-wrap__border-bottom', {width:'60%', duration: 0.7, ease: 'power1.inOut'});
            }
        }

        if (instrumentsIntro[0]) {
            if($(window).scrollTop() + $(window).height() >= instrumentsIntro.offset().top + instrumentsIntro.height()) {
                instrumentsIntro.addClass('animate');
                if (!TriggerInstrumentsIntro) {
                    TriggerInstrumentsIntro = true;
                    setTimeout(function () {
                        instrumentsIntro.addClass('hover');
                    }, 300)
                }
            }
        }
        if (instrumentsContentImages.length) {
            for (let i = 0; i < instrumentsContentImages.length; i++) {
                if($(window).scrollTop() + $(window).height()/1.2 >= $(instrumentsContentImages[i]).offset().top + $(instrumentsContentImages[i]).height()) {
                    $(instrumentsContentImages[i]).addClass('animate');
                }
            }

        }

    });

    var saySLider = $('.say-slider');
    saySLider.slick({
        infinite: true,
        centerMode: true,
        centerPadding: '250px',
        focusOnSelect: true,
        initialSlide: 2,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    centerPadding: '200px',
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    centerPadding: '0',
                    centerMode: false,
                }
            }
        ]
    });
    $(".say-slider-next").click(function() {
        saySLider.slick("slickNext");
    });
    $(".say-slider-previous").click(function() {
        saySLider.slick("slickPrev");
    });

    var instrumentsGallery = $('.try-instruments__gallery');
    instrumentsGallery.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
    $(".try-instruments__gallery--arrow--next").click(function() {
        instrumentsGallery.slick("slickNext");
    });
    $(".try-instruments__gallery--arrow--prev").click(function() {
        instrumentsGallery.slick("slickPrev");
    });

    $(".save-block__content li").hover(function(e) {
            $('.save-block__content li').removeClass('active');
            // Mouseover state
            $(this).addClass('active');
        },
        function(e) {
            // Mouseout state

        });

    $('.instruments__items').hover(function(e) {
            $('.instruments__title').removeClass('hover');
            $('.instruments__Link').removeClass('hover');
            // Mouseover state
            $(this).find('.instruments__title').addClass('hover');
            $(this).find('.instruments__Link').addClass('hover');
        },
        function(e) {
            // Mouseout state
            // $(this).siblings('.instruments__title').removeClass('hover');
        });

    function selectChange() {
        if ($('select[name=language]')[0].value === "none") {
            $('select[name]').css('color', '#ccc');
        } else {
            $('select[name]').css('color', '#10100F');
        }
    }

    function initHelpsTabs() {
        let $tabsLinks = $('.tabs__links > a');
        let $tabsContents = $('.tabs__contents > img');
        //Init Actives
        let idTabAcitve = $('.tabs__links > a.active').attr('href');
        $(idTabAcitve).addClass('active');
        $(idTabAcitve).fadeIn(400);

        $tabsLinks.click(function (e) {
            e.preventDefault();
            let idTab = $(this).attr('href');
            $tabsLinks.removeClass('active');
            $(this).addClass('active');

            $tabsContents.removeClass('active');
            $tabsContents.fadeOut(200);
            $(idTab).addClass('active');
            $(idTab).fadeIn(400);
        });
    }

    function initHelpsSlider() {
        $('.tabs__contents').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.tabs__links').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            arrows: false,
            focusOnSelect: true
        });
    }


    $(".header .menu-item-has-children > a").click(function (e) {
        e.preventDefault();
        var self = $(this);
        $('.menu-item-has-children > a').each(function (_, item) {
            if (item !== self[0]) {
                $(item).removeClass('open');
                $(item).next('.sub-menu').removeClass('open');
            }
        })

        if (self.hasClass('open')) {
            self.removeClass('open');
            self.next('.sub-menu').removeClass('open');
        } else {
            self.addClass('open');
            self.next('.sub-menu').addClass('open');
        }

    });
    $(document).click(function (e){
        let div = $(".header .menu-item-has-children");
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            $('.sub-menu').removeClass('open');
            $(".header .menu-item-has-children > a").removeClass('open');
        }
    });

    function langSelect() {
        let select;
        select = $('#lang_choice_1')[0];
        $(select).css({
            'position': 'fixed',
            'top': '-100vh',
            'left': '-100vw'
        });
        $(select).after("<div class='menu-item-has-children'><a href='#' id='LangLink' class='dropdown-link'>" + select.options[select.options.selectedIndex].value.toUpperCase() + "</a></div>");
        let LangLink;
        LangLink = $('#LangLink')[0];
        $(LangLink).after("<ul id='LangList' class='sub-menu'></ul>");
        let langList = $("#LangList")[0];
        for (let i = 0; i < select.options.length; i++) {
            let optionText;
            optionText = select.options[i].text;
            let optionValue;
            optionValue = select.options[i].value;
            let activeClass;
            activeClass = (i === select.options.selectedIndex) ? 'active' : '';
            $(langList).append("<li data-val='" + optionValue + "' class='lang-item " + activeClass + "'>" + optionText + "</li>");
            $('.lang-item').click(function (e) {
                location.href = urls_1[$(this)[0].dataset.val];
            })
        }

    }

    //Tariff
    var tariffObj = {
        month: {
            big: '3$',
            small: '36$'
        },
        half_year: {
            big: '2,5$',
            small: '30$'
        },
        year: {
            big: '2$',
            small: '24$'
        }
    }
    var radios = $('input[name=tariff]');
    function checkTariff() {
        radios.each(function (_, item) {
            if (item.checked) {
                $('.tariff__price--big').html(tariffObj[item.value].big);
                $('.tariff__price--small span').html(tariffObj[item.value].small);
            }
        })
    }
    checkTariff();
    radios.change(checkTariff);

    $("a.tariff__more").click(function() {
        var elementClick = $(this).attr("href")
        var destination = $(elementClick).offset().top;
        jQuery("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 1000);
        return false;
    });

    $('.faq-list__question').click(function (event) {
        $(this).toggleClass('active');
        $(this).siblings(".faq-list__answer").slideToggle();
        event.stopPropagation();
    });

});



